import React from 'react';
import { Clock, MapPin, DollarSign, Users } from 'lucide-react';

const RouteDetails = ({ route, t }) => {
  // Форматирование даты и времени
  const formatDateTime = (date) => {
    if (!date) return '';
    if (typeof date === 'string') {
      date = new Date(date);
    }
    if (date instanceof Date) {
      return date.toLocaleString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    return '';
  };

  // Форматирование только времени
  const formatTime = (date) => {
    if (!date) return '';
    if (typeof date === 'string') {
      date = new Date(date);
    }
    if (date instanceof Date) {
      return date.toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    return '';
  };

  if (!route) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-4">
      <h2 className="text-2xl font-bold mb-4 text-blue-900 flex items-center">
        {t.routeDetails}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Время */}
        <div className="flex items-center">
          <Clock className="mr-2 text-blue-500" size={24} />
          <div>
            <p className="font-semibold">
              {t.departure}: {formatTime(route.departureTime)}
            </p>
            <p className="font-semibold">
              {t.arrival}: {formatTime(route.arrivalTime)}
            </p>
            <p className="text-sm text-gray-600">
              {formatDateTime(route.departureTime)}
            </p>
          </div>
        </div>

        {/* Маршрут */}
        <div className="flex items-center">
          <MapPin className="mr-2 text-blue-500" size={24} />
          <div>
            <p>{t.from}: {route.departureStationId}</p>
            <p>{t.to}: {route.arrivalStationId}</p>
          </div>
        </div>

        {/* Места */}
        {route.freeSeatsCount !== undefined && (
          <div className="flex items-center">
            <Users className="mr-2 text-blue-500" size={24} />
            <p>
              {t.availableSeats}: {route.freeSeatsCount}
            </p>
          </div>
        )}

        {/* Цена */}
        {route.price && (
          <div className="flex items-center">
            <DollarSign className="mr-2 text-blue-500" size={24} />
            <p className="font-bold text-lg">
              {route.price} {route.currency || 'EUR'}
            </p>
          </div>
        )}
      </div>

      {/* Дополнительная информация */}
      {route.travelTime && (
        <div className="mt-4 pt-4 border-t">
          <p className="text-gray-600">
            {t.duration}: {route.travelTime}
          </p>
        </div>
      )}

      {/* Пересадки */}
      {route.transfersCount > 0 && (
        <div className="mt-2">
          <p className="text-gray-600">
            {t.transfers}: {route.transfersCount}
          </p>
        </div>
      )}

      {/* Задержка */}
      {route.delay && (
        <div className="mt-2 text-red-500">
          <p>
            {t.delay}: {route.delay}
          </p>
        </div>
      )}
    </div>
  );
};

export default RouteDetails;