import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { AlertCircle } from 'lucide-react';

// Components
import SearchForm from './components/SearchForm';
import RouteList from './components/RouteList';
import RouteDetails from './components/RouteDetails';
import SeatSelection from './components/SeatSelection';
import BookingForm from './components/BookingForm';
import BookingConfirmation from './components/BookingConfirmation';
import PaymentForm from './components/PaymentForm';
import LanguageSelector from './components/LanguageSelector';
import PopularDestinations from './components/PopularDestinations';
import LoadingScreen from './components/LoadingScreen';
import FilterSort from './components/FilterSort';

// Services & Utils
import api from './services/regiojet-api';
import { LANGUAGES, TRANSLATIONS } from './utils/translations';

const App = () => {
  // State declarations
  const [view, setView] = useState('search');
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(LANGUAGES.EN);
  const [bookingStep, setBookingStep] = useState('route');
  const [showPayment, setShowPayment] = useState(false);
  
  // Ref for transitions
  const nodeRef = useRef(null);

  // Get translations
  const t = TRANSLATIONS[language];

  // Initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        const [locationsData, tariffsData, seatClassesData] = await Promise.all([
          api.getLocations().catch(error => {
            console.error('Error loading locations:', error);
            return [];
          }),
          api.getTariffs().catch(error => {
            console.error('Error loading tariffs:', error);
            return [];
          }),
          api.getSeatClasses().catch(error => {
            console.error('Error loading seat classes:', error);
            return [];
          })
        ]);

        console.log('Initial data loaded:', {
          locations: locationsData.length,
          tariffs: tariffsData.length,
          seatClasses: seatClassesData.length
        });
      } catch (err) {
        console.error('Error loading initial data:', err);
        setError(t.errorLoadingData);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, [t]);

  // Reset all states to initial
  const resetState = () => {
    setSelectedRoute(null);
    setSelectedSeat(null);
    setBooking(null);
    setShowPayment(false);
    setBookingStep('route');
    setError(null);
  };

  // Handler for payment completion
  const handlePaymentComplete = async (ticket) => {
    try {
      setLoading(true);
      const ticketDetails = await api.api.get(`/tickets/create`, {
        params: {
          ticketId: ticket.id
        }
      });
  
      setBooking({
        ...ticketDetails.data,
        status: 'PAID'
      });
      setShowPayment(false);
      setView('confirmation');
    } catch (error) {
      console.error('Error updating ticket:', error);
      setError(t.ticketUpdateError);
    } finally {
      setLoading(false);
    }
  };

  // Handler for seat selection
  const handleSeatSelect = (seat) => {
    if (!seat) {
      setError(t.seatSelectionError);
      return;
    }

    console.log('Selected seat:', seat);
    setSelectedSeat(seat);
    setBookingStep('details');
  };

  // Handler for route selection
  const handleRouteSelect = async (route) => {
    setLoading(true);
    setError(null);
    try {
      if (!route?.id) {
        throw new Error('Invalid route selected');
      }

      console.log('Processing route selection:', route);

      const routeDetails = await api.getRouteDetails({
        routeId: route.id,
        fromStationId: route.departureStationId,
        toStationId: route.arrivalStationId,
        departureDate: route.departureTime
      });

      console.log('Route details received:', routeDetails);

      if (!routeDetails) {
        throw new Error('Failed to fetch route details');
      }

      const formattedRoute = {
        ...routeDetails,
        id: route.id || routeDetails.id,
        departureStationId: route.departureStationId || routeDetails.departureStationId,
        arrivalStationId: route.arrivalStationId || routeDetails.arrivalStationId,
        departureTime: route.departureTime || routeDetails.departureTime,
        arrivalTime: route.arrivalTime || routeDetails.arrivalTime,
        price: route.price || routeDetails.priceFrom,
        currency: route.currency || routeDetails.currency || 'EUR',
        seatClass: routeDetails.priceClasses?.[0]?.seatClassKey || 'REGULAR',
        mainSectionId: routeDetails.mainSectionId,
        priceSource: routeDetails.priceClasses?.[0]?.priceSource,
        sections: [{
          sectionId: routeDetails.mainSectionId,
          fromStationId: route.departureStationId,
          toStationId: route.arrivalStationId
        }]
      };

      console.log('Route formatted:', formattedRoute);

      setSelectedRoute(formattedRoute);
      setBookingStep('seat');
      setView('booking');
    } catch (error) {
      console.error('Route selection error:', error);
      setError(t.routeDetailsError);
    } finally {
      setLoading(false);
    }
  };

  // Handler for search
  const handleSearch = async (searchParams) => {
    setLoading(true);
    setError(null);
    try {
      if (!searchParams?.fromLocationId || !searchParams?.toLocationId) {
        throw new Error('Invalid search parameters');
      }

      console.log('Searching routes with params:', searchParams);

      const routesData = await api.searchRoutes(searchParams);
      
      if (!routesData?.routes) {
        throw new Error('No routes found');
      }

      console.log('Routes found:', routesData.routes.length);

      setRoutes(routesData.routes);
      setFilteredRoutes(routesData.routes);
      setView('results');
    } catch (error) {
      console.error('Search error:', error);
      setError(t.searchError);
    } finally {
      setLoading(false);
    }
  };

// В компоненте App.js добавим обработку оплаты

const handleBooking = async (bookingData) => {
  try {
    if (!selectedRoute?.id || !selectedSeat) {
      throw new Error('Missing route or seat data');
    }

    const ticketRequest = {
      routeId: selectedRoute.id,
      selectedRoute: selectedRoute,
      selectedSeat: {
        ...selectedSeat,
        seatClass: selectedSeat.seatClass || 'REGULAR'
      },
      route: {
        routeId: selectedRoute.id,
        seatClass: selectedSeat.seatClass || 'REGULAR',
        priceSource: selectedRoute.priceSource,
        sections: [{
          section: {
            sectionId: selectedRoute.mainSectionId || selectedRoute.id,
            fromStationId: selectedRoute.departureStationId,
            toStationId: selectedRoute.arrivalStationId
          },
          selectedSeats: [{
            sectionId: selectedRoute.mainSectionId || selectedRoute.id,
            vehicleNumber: String(selectedSeat.vehicleNumber),
            seatIndex: String(selectedSeat.number || selectedSeat.index)
          }]
        }]
      },
      passengers: bookingData.passengers.map(p => ({
        ...p,
        firstName: p.firstName.trim(),
        lastName: p.lastName.trim(),
        email: p.email.trim(),
        phone: p.phone.trim(),
        tariff: p.tariff || 'REGULAR'
      }))
    };

    console.log('Creating ticket request:', JSON.stringify(ticketRequest, null, 2));

    const { ticket, token } = await api.createTicket(ticketRequest);

    console.log('Ticket created:', {
      id: ticket.id,
      hasToken: !!token,
      tokenPreview: token ? `${token.substring(0, 10)}...` : null
    });

    setBooking({
      id: ticket.id,
      token: token,
      price: ticket.price,
      currency: ticket.currency,
      controlHash: ticket.controlHash
    });

    setShowPayment(true);
  } catch (error) {
    console.error('Booking error:', error);
    setError(error.message || t.bookingError);
  }
};

  // Handler for booking cancellation
  const handleCancelBooking = async (ticketId) => {
    setLoading(true);
    setError(null);
    try {
      if (!booking?.accountCode || !ticketId || !booking?.controlHash) {
        throw new Error('Invalid booking data for cancellation');
      }

      console.log('Cancelling ticket:', ticketId);

      await api.cancelTicket(booking.accountCode, ticketId, booking.controlHash);
      
      console.log('Ticket cancelled successfully');
      
      resetState();
      setView('search');
    } catch (error) {
      console.error('Cancellation error:', error);
      setError(t.cancelError);
    } finally {
      setLoading(false);
    }
  };

  // Handler for filter/sort
  const handleFilterSort = ({ priceRange, sortBy, sortOrder }) => {
    try {
      let filtered = [...routes];

      // Apply price filter
      if (priceRange.min || priceRange.max) {
        filtered = filtered.filter(route => {
          const price = Number(route.price);
          const min = Number(priceRange.min) || 0;
          const max = Number(priceRange.max) || Infinity;
          return price >= min && price <= max;
        });
      }

      // Apply sorting
      filtered.sort((a, b) => {
        let comparison = 0;
        switch (sortBy) {
          case 'price':
            comparison = Number(a.price) - Number(b.price);
            break;
          case 'departureTime':
            comparison = new Date(a.departureTime) - new Date(b.departureTime);
            break;
          case 'arrivalTime':
            comparison = new Date(a.arrivalTime) - new Date(b.arrivalTime);
            break;
          default:
            comparison = 0;
        }
        return sortOrder === 'desc' ? -comparison : comparison;
      });

      setFilteredRoutes(filtered);
    } catch (error) {
      console.error('Filter/sort error:', error);
      setError(t.filterError);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Language selector */}
      <div className="absolute top-4 right-4 z-10">
        <LanguageSelector
          currentLanguage={language}
          onLanguageChange={setLanguage}
        />
      </div>

      <div className="max-w-4xl mx-auto p-4">
        {/* Logo */}
        <div className="mb-8 flex justify-center">
          <img
            src="/images/logo.png"
            alt="RegioJet"
            className="h-12 object-contain"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/images/placeholder-logo.png';
            }}
          />
        </div>

        {/* Main content */}
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={view}
            nodeRef={nodeRef}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div ref={nodeRef}>
              {view === 'search' && (
                <div>
                  <SearchForm onSearch={handleSearch} t={t} />
                  <PopularDestinations t={t} />
                </div>
              )}

              {view === 'results' && (
                <div>
                  <button
                    onClick={() => setView('search')}
                    className="mb-4 text-blue-500 hover:text-blue-600 flex items-center"
                  >
                    ← {t.backToSearch}
                  </button>
                  <FilterSort onFilterSort={handleFilterSort} t={t} />
                  <RouteList
                    routes={filteredRoutes}
                    onRouteSelect={handleRouteSelect}
                    t={t}
                  />
                </div>
              )}

              {view === 'booking' && selectedRoute && (
                <div>
                  <button
                    onClick={() => setView('results')}
                    className="mb-4 text-blue-500 hover:text-blue-600 flex items-center"
                  >
                    ← {t.backToResults}
                  </button>

                  <RouteDetails route={selectedRoute} t={t} />

                  {bookingStep === 'seat' && (
                    <SeatSelection
                      routeId={selectedRoute.mainSectionId || selectedRoute.id}
                      selectedRoute={selectedRoute}
                      onSeatSelect={handleSeatSelect}
                      t={t}
                    />
                  )}

                  {bookingStep === 'details' && (
                    <BookingForm
                      onBook={handleBooking}
                      route={selectedRoute}
                      seat={selectedSeat}
                      t={t}
                    />
                  )}
                </div>
              )}

              {view === 'confirmation' && booking && (
                <BookingConfirmation
                  booking={booking}
                  onClose={() => {
                    resetState();
                    setView('search');
                  }}
                  onCancel={handleCancelBooking}
                  t={t}
                />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>

        {/* Payment Form */}
        {showPayment && booking && (
          <PaymentForm
            booking={booking}
            onPaymentComplete={handlePaymentComplete}
            onClose={() => setShowPayment(false)}
            t={t}
          />
        )}

        {/* Loading overlay */}
        {loading && <LoadingScreen t={t} />}

        {/* Error modal */}
        {error && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md">
              <div className="flex items-center mb-4">
                <AlertCircle className="text-red-500 mr-2" />
                <h3 className="text-lg font-semibold">{t.error}</h3>
              </div>
              <p className="text-gray-600 mb-4">{error}</p>
              <button
                onClick={() => setError(null)}
                className="w-full py-2 px-4 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                {t.close}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;