// src/components/PopularDestinations.js

import React from 'react';

const PopularDestinations = ({ t }) => {
  const destinations = [
    {
      id: 1,
      name: 'Prague',
      price: '249 CZK',
      image: '/images/prague.jpg',
    },
    {
      id: 2,
      name: 'Brno',
      price: '199 CZK',
      image: '/images/brno.jpg',
    }
  ];

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-2 text-blue-900">
        {t.popularDestinations}
      </h2>
      <p className="mb-6 text-gray-600">
        {t.chooseDestination}
      </p>
      <div className="grid grid-cols-2 gap-4">
        {destinations.map((destination) => (
          <div 
            key={destination.id} 
            className="relative rounded-lg overflow-hidden shadow-md h-48 group transition-transform duration-300 hover:scale-105"
          >
            <img 
              src={destination.image} 
              alt={destination.name} 
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null; // Предотвращаем рекурсию
                e.target.src = '/images/placeholder.jpg'; // Fallback изображение
              }}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70" />
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <h3 className="text-white font-bold text-xl">
                {destination.name}
              </h3>
              <div className="flex items-center justify-between mt-2">
                <p className="text-white text-sm">
                  {t.from} {destination.price}
                </p>
                <div className="bg-white/10 backdrop-blur-sm px-3 py-1 rounded-full">
                  <span className="text-white text-sm">
                    {t.viewDetails}
                  </span>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularDestinations;