import React, { useState } from 'react';
import axios from 'axios';
import { CreditCard, Check, X, AlertCircle, Loader, Printer, QrCode } from 'lucide-react';
import api from '../services/regiojet-api';

const PaymentForm = ({ booking, onPaymentComplete, onClose, t }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentResult, setPaymentResult] = useState(null);
  const [processingStep, setProcessingStep] = useState(null);

  const handlePaymentStart = async () => {
    setLoading(true);
    setError(null);
    
    try {
      if (!booking?.id || !booking?.token) {
        throw new Error('Missing booking data');
      }

      console.log('Starting payment process:', {
        bookingId: booking.id,
        tokenPreview: booking.token.substring(0, 10) + '...'
      });

      setProcessingStep('authentication');
      
      // Используем чистый токен без Bearer
      const token = booking.token.trim();
      
      console.log('Authentication attempt:', {
        bookingId: booking.id,
        tokenLength: token.length,
        tokenPreview: token.substring(0, 20) + '...'
      });

      const authData = await api.authenticate(token);

      console.log('Authentication successful:', {
        accountCode: authData.accountCode
      });

      setProcessingStep('payment');
      const paymentResponse = await api.payment.createPayment(booking.id, token);

      if (paymentResponse.success) {
        setPaymentResult({
          success: true,
          ticket: {
            id: booking.id,
            accountCode: authData.accountCode,
            token: token
          }
        });

        onPaymentComplete?.({
          ...paymentResponse,
          accountCode: authData.accountCode
        });
      }

    } catch (err) {
      console.error('Payment process failed:', {
        step: processingStep,
        error: err.message,
        response: err.response?.data,
        status: err.response?.status,
        originalError: err
      });
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
      setProcessingStep(null);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full relative">
        {/* Close button */}
        <button
          onClick={onClose}
          disabled={loading}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <X size={24} />
        </button>

        <div className="space-y-6">
          {/* Title */}
          <h2 className="text-2xl font-bold text-gray-900">
            {loading ? t.processing : t.payment}
          </h2>

          {/* Ticket information */}
          {booking && (
            <div className="bg-gray-50 p-4 rounded-lg space-y-2">
              <p className="font-medium text-gray-900">{t.ticketId}: {booking.id}</p>
              <p className="font-medium text-gray-900">
                {t.totalAmount}: {booking.price} {booking.currency}
              </p>
            </div>
          )}

          {/* Loading indicator */}
          {loading && (
            <div className="text-center py-4">
              <Loader className="animate-spin h-8 w-8 text-blue-500 mx-auto" />
              <p className="mt-2 text-gray-600">
                {
                  processingStep === 'payment' ? t.processingPayment :
                  processingStep === 'authentication' ? t.authenticating :
                  t.processing
                }
              </p>
            </div>
          )}

          {/* Payment success */}
          {paymentResult?.success && (
            <div className="bg-green-50 p-4 rounded-lg text-green-700">
              <div className="flex items-center space-x-2">
                <Check size={20} />
                <span className="font-medium">{t.paymentSuccess}</span>
              </div>
            </div>
          )}

          {/* Error display */}
          {error && (
            <div className="bg-red-50 p-4 rounded-lg text-red-600">
              <div className="flex items-center space-x-2">
                <AlertCircle size={20} />
                <span>{error}</span>
              </div>
              {!paymentResult?.success && (
                <button
                  onClick={() => setError(null)}
                  className="mt-2 text-sm text-red-600 hover:text-red-800"
                >
                  {t.tryAgain}
                </button>
              )}
            </div>
          )}

          {/* Payment button */}
          {!paymentResult?.success && !loading && (
            <button
              onClick={handlePaymentStart}
              disabled={loading}
              className={`
                w-full py-3 rounded-lg text-white font-medium transition-colors
                ${loading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}
              `}
            >
              <div className="flex items-center justify-center space-x-2">
                <CreditCard size={20} />
                <span>{t.pay}</span>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;