import React from 'react';
import { X, Check, Clock, MapPin, User, CreditCard } from 'lucide-react';

const BookingConfirmation = ({ booking, onClose, onCancel, t }) => {
  if (!booking) {
    return null;
  }

  const ticket = booking.tickets?.[0] || booking;

  // Форматирование даты
  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch {
      return dateString;
    }
  };

  // Форматирование цены
  const formatPrice = (price, currency) => {
    return `${price} ${currency || 'EUR'}`;
  };

  // Печать билета
  const handlePrintTicket = async () => {
    try {
      const cleanToken = booking.token.trim().replace(/^Bearer\s+/i, '');
      const authToken = `Bearer ${cleanToken}`;

      console.log('Printing ticket:', {
        id: ticket.id,
        accountCode: ticket.accountCode,
        tokenPreview: cleanToken.substring(0, 20) + '...'
      });

      const response = await fetch(
        `/api/tickets/${ticket.accountCode}/${ticket.id}/print`,
        {
          headers: {
            'Authorization': authToken,
            'Accept': 'text/html'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get ticket print data');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const printWindow = window.open(url, '_blank');
      
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 100);
        };
      } else {
        throw new Error('Popup blocked. Please allow popups and try again.');
      }
    } catch (error) {
      console.error('Print error:', error);
      alert(t.printError || 'Failed to print ticket');
    }
  };

  // Показать QR код
  const handleShowQR = async () => {
    try {
      const cleanToken = booking.token.trim().replace(/^Bearer\s+/i, '');
      const authToken = `Bearer ${cleanToken}`;

      console.log('Getting QR code:', {
        id: ticket.id,
        accountCode: ticket.accountCode,
        tokenPreview: cleanToken.substring(0, 20) + '...'
      });

      const response = await fetch(
        `/api/tickets/${ticket.accountCode}/${ticket.id}/qrcode/png`,
        {
          headers: {
            'Authorization': authToken,
            'Accept': 'image/png'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get QR code');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const qrWindow = window.open(url, '_blank');
      
      if (qrWindow) {
        qrWindow.onload = () => {
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 100);
        };
      } else {
        throw new Error('Popup blocked. Please allow popups and try again.');
      }
    } catch (error) {
      console.error('QR error:', error);
      alert(t.qrError || 'Failed to show QR code');
    }
  };

  // Отмена билета
  const handleCancel = async () => {
    try {
      if (!ticket.customerActions?.cancel) {
        throw new Error('Ticket cannot be cancelled');
      }
      
      if (window.confirm(t.cancelConfirmation || 'Are you sure you want to cancel this booking?')) {
        await onCancel(ticket.id);
      }
    } catch (error) {
      console.error('Cancel error:', error);
      alert(t.cancelError || 'Failed to cancel booking');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-lg w-full relative">
        {/* Кнопка закрытия */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>

        {/* Заголовок */}
        <div className="flex items-center mb-6">
          <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center mr-4">
            <Check className="text-green-500" size={24} />
          </div>
          <div>
            <h2 className="text-2xl font-bold text-gray-900">{t.bookingConfirmed}</h2>
            <p className="text-gray-500">{t.ticketId}: {ticket.id}</p>
          </div>
        </div>

        {/* Детали маршрута */}
        <div className="space-y-4 mb-6">
          {/* Время */}
          <div className="flex items-start">
            <Clock className="text-gray-400 mt-1 mr-3" size={20} />
            <div>
              <p className="font-medium">{t.departure}</p>
              <p className="text-gray-600">
                {formatDate(ticket.departureTime)}
              </p>
            </div>
          </div>

          {/* Маршрут */}
          <div className="flex items-start">
            <MapPin className="text-gray-400 mt-1 mr-3" size={20} />
            <div>
              <p className="font-medium">{t.route}</p>
              <p className="text-gray-600">
                {ticket.departureStationId} → {ticket.arrivalStationId}
              </p>
            </div>
          </div>

          {/* Пассажир */}
          {ticket.passengers?.[0] && (
            <div className="flex items-start">
              <User className="text-gray-400 mt-1 mr-3" size={20} />
              <div>
                <p className="font-medium">{t.passenger}</p>
                <p className="text-gray-600">
                  {ticket.passengers[0].firstName} {ticket.passengers[0].surname}
                </p>
                <p className="text-gray-500 text-sm">{ticket.passengers[0].email}</p>
              </div>
            </div>
          )}

          {/* Цена */}
          <div className="flex items-start">
            <CreditCard className="text-gray-400 mt-1 mr-3" size={20} />
            <div>
              <p className="font-medium">{t.price}</p>
              <p className="text-gray-600">
                {formatPrice(ticket.price, ticket.currency)}
              </p>
            </div>
          </div>
        </div>

        {/* Действия */}
        <div className="space-y-3">
          {ticket.customerActions?.print && (
            <button
              onClick={handlePrintTicket}
              className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              {t.printTicket}
            </button>
          )}

          {ticket.customerActions?.qr && (
            <button
              onClick={handleShowQR}
              className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              {t.showQR}
            </button>
          )}

          {ticket.customerActions?.cancel && (
            <button
              onClick={handleCancel}
              className="w-full py-2 px-4 bg-red-100 text-red-700 rounded-md hover:bg-red-200"
            >
              {t.cancelBooking}
            </button>
          )}

          <button
            onClick={onClose}
            className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            {t.close}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;