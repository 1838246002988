import React, { useState, useEffect } from 'react';
import { Clock, MapPin, DollarSign, Info } from 'lucide-react';
import api from '../services/regiojet-api';

const SeatSelection = ({ routeId, selectedRoute, onSeatSelect, t }) => {
  // States
  const [seats, setSeats] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch seats when route is selected
  useEffect(() => {
    const fetchSeats = async () => {
      try {
        setLoading(true);
        setError(null);

        if (!routeId || !selectedRoute?.departureStationId || !selectedRoute?.arrivalStationId) {
          throw new Error('Missing required route data');
        }

        const seatsData = await api.getFreeSeats(routeId, {
          fromStationId: selectedRoute.departureStationId,
          toStationId: selectedRoute.arrivalStationId,
          tariff: selectedRoute.tariff || 'REGULAR',
          seatClass: selectedRoute.seatClass || 'REGULAR'
        });

        if (!seatsData?.length) {
          setSeats([]);
          return;
        }

        setSeats(seatsData);
      } catch (err) {
        console.error('Error fetching seats:', err);
        setError(err.message || t.errorFetchingSeats);
      } finally {
        setLoading(false);
      }
    };

    if (routeId && selectedRoute) {
      fetchSeats();
    }
  }, [routeId, selectedRoute, t]);

  // Handle seat selection
  const handleSeatSelect = (seat, vehicle) => {
    if (!seat.available) {
      return;
    }

    // Create minimal seat data structure required by API
    const seatData = {
      number: seat.number,
      vehicleNumber: vehicle.number,
      seatClass: seat.seatClass || 'REGULAR'
    };

    console.log('Selected seat:', seatData);
    setSelectedSeat(seatData);
    onSeatSelect(seatData);
  };

  // Loading state
  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        <p className="mt-4 text-gray-600">{t.loadingSeats}</p>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
        <div className="flex items-center space-x-2 text-red-600 mb-2">
          <Info size={20} />
          <p className="font-medium">{error}</p>
        </div>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 bg-red-100 text-red-700 rounded hover:bg-red-200 transition-colors"
        >
          {t.retry}
        </button>
      </div>
    );
  }

  // No seats available
  if (!seats.length) {
    return (
      <div className="p-6 text-center">
        <p className="text-gray-600 mb-2">{t.noSeatsAvailable}</p>
        <p className="text-sm text-gray-500">{t.selectDifferentRoute}</p>
      </div>
    );
  }

  // Main content
  return (
    <div className="mt-6 space-y-6">
      {/* Route Info */}
      <div className="bg-blue-50 p-4 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-start space-x-2">
            <Clock className="text-blue-500 mt-1" size={20} />
            <div>
              <p className="font-medium">{t.departure}</p>
              <p>{new Date(selectedRoute.departureTime).toLocaleString()}</p>
            </div>
          </div>
          <div className="flex items-start space-x-2">
            <MapPin className="text-blue-500 mt-1" size={20} />
            <div>
              <p className="font-medium">{t.route}</p>
              <p>{selectedRoute.departureStationId} → {selectedRoute.arrivalStationId}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Seats Grid */}
      {seats.map(section => (
        <div key={section.sectionId} className="space-y-4">
          {section.vehicles.map(vehicle => (
            <div key={vehicle.number} className="bg-white p-6 rounded-lg shadow-md">
              {/* Vehicle Header */}
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-lg font-medium">
                  {t.vehicle} {vehicle.number}
                  {vehicle.standardKey && (
                    <span className="ml-2 text-sm text-gray-500">
                      ({vehicle.standardKey})
                    </span>
                  )}
                </h4>
                {vehicle.services?.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {vehicle.services.map((service, index) => (
                      <span 
                        key={index}
                        className="px-2 py-1 bg-gray-100 rounded text-xs text-gray-600"
                      >
                        {service}
                      </span>
                    ))}
                  </div>
                )}
              </div>

              {/* Seats Grid */}
              <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 gap-4">
                {vehicle.seats
                  .filter(seat => seat.number && seat.available)
                  .map(seat => (
                    <button
                      key={`${vehicle.number}-${seat.number}`}
                      onClick={() => handleSeatSelect(seat, vehicle)}
                      className={`
                        p-4 rounded-lg border transition-colors duration-200
                        ${selectedSeat?.number === seat.number && 
                          selectedSeat?.vehicleNumber === vehicle.number
                          ? 'bg-blue-500 text-white border-blue-600 shadow-md'
                          : 'bg-white hover:bg-blue-50 border-gray-200'
                        }
                      `}
                    >
                      <div className="text-center">
                        <div className="font-bold text-lg mb-1">
                          {seat.number}
                        </div>
                        {seat.price && (
                          <div className="flex items-center justify-center text-xs space-x-1">
                            <DollarSign size={12} />
                            <span>{seat.price} {seat.currency}</span>
                          </div>
                        )}
                      </div>
                    </button>
                  ))}
              </div>
            </div>
          ))}
        </div>
      ))}

      {/* Selected Seat Info */}
      {selectedSeat && (
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t shadow-lg">
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-semibold text-blue-900">
                  {t.selectedSeat}: {selectedSeat.number}
                </p>
                <p className="text-sm text-gray-600">
                  {t.vehicle}: {selectedSeat.vehicleNumber}
                </p>
              </div>
              <button
                onClick={() => onSeatSelect(selectedSeat)}
                className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                {t.confirm}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SeatSelection;