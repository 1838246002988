import React from 'react';
import { Loader } from 'lucide-react';

const LoadingScreen = ({ t }) => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <Loader className="animate-spin text-blue-500 mb-4" size={48} />
    <h2 className="text-2xl font-semibold text-gray-700">{t.loading}</h2>
    <p className="text-gray-500 mt-2">{t.pleaseWait}</p>
  </div>
);

export default LoadingScreen;