import React from 'react';
import { Clock, MapPin, DollarSign, Users, Train, Bus } from 'lucide-react';

const RouteList = ({ routes = [], onRouteSelect, t }) => {
  // Форматирование времени
  const formatTime = (date) => {
    if (!(date instanceof Date)) return '';
    return date.toLocaleTimeString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Получение иконки транспорта
  const getVehicleIcon = (vehicleTypes) => {
    if (vehicleTypes?.includes('TRAIN')) {
      return <Train className="h-4 w-4" />;
    }
    return <Bus className="h-4 w-4" />;
  };

  // Форматирование цены
  const formatPrice = (price, currency) => {
    return `${price} ${currency}`;
  };

  const RouteItem = ({ route }) => (
    <div 
      className="bg-white rounded-lg shadow-md p-4 mb-4 hover:shadow-lg transition-shadow cursor-pointer"
      onClick={() => onRouteSelect({
        id: route.id,
        departureStationId: route.departureStationId,
        arrivalStationId: route.arrivalStationId,
        departureTime: route.departureTime, // Важно передать дату
        arrivalTime: route.arrivalTime,
        price: route.price,
        currency: route.currency,
        vehicleTypes: route.vehicleTypes,
        freeSeatsCount: route.freeSeatsCount,
        mainSectionId: route.mainSectionId,
        priceSource: route.priceSource,
        transfersCount: route.transfersCount,
        travelTime: route.travelTime
      })}
    >
      {/* Основная информация */}
      <div className="flex justify-between items-start mb-4">
        <div className="flex-1">
          {/* Время */}
          <div className="flex items-center mb-2">
            <Clock className="text-blue-500 mr-2" size={20} />
            <span className="font-semibold">
              {formatTime(route.departureTime)} - {formatTime(route.arrivalTime)}
            </span>
          </div>
          
          {/* Маршрут */}
          <div className="flex items-center text-gray-600">
            <MapPin className="text-blue-500 mr-2" size={20} />
            <span>{route.fromLocationId} → {route.toLocationId}</span>
          </div>
        </div>

        {/* Цена и места */}
        <div className="text-right">
          <div className="flex items-center justify-end mb-2">
            <DollarSign className="text-green-500 mr-1" size={20} />
            <span className="font-bold text-lg">
              {formatPrice(route.price, route.currency)}
            </span>
          </div>
          <div className="flex items-center justify-end text-gray-600">
            <Users className="mr-1" size={16} />
            <span>{route.freeSeatsCount} {t.seatsAvailable}</span>
          </div>
        </div>
      </div>

      {/* Дополнительная информация */}
      <div className="flex items-center justify-between text-sm text-gray-500 border-t pt-2">
        {/* Тип транспорта */}
        <div className="flex items-center space-x-2">
          {getVehicleIcon(route.vehicleTypes)}
          <span>
            {route.vehicleTypes?.includes('TRAIN') ? t.train : t.bus}
          </span>
        </div>

        {/* Длительность */}
        {route.travelTime && (
          <span>
            {t.duration}: {route.travelTime}
          </span>
        )}
      </div>

      {/* Пересадки */}
      {route.transfersCount > 0 && (
        <div className="mt-2 text-sm text-blue-600 flex items-center">
          <Clock className="mr-1" size={14} />
          <span>{t.transfers}: {route.transfersCount}</span>
        </div>
      )}

      {/* Задержка */}
      {route.delay && (
        <div className="mt-2 text-sm text-red-500 flex items-center">
          <Clock className="mr-1" size={14} />
          <span>{t.delay}: {route.delay}</span>
        </div>
      )}
    </div>
  );

  // Если нет маршрутов
  if (!routes.length) {
    return (
      <div className="text-center py-8 text-gray-500">
        {t.noRoutesFound}
      </div>
    );
  }

  // Основной рендер
  return (
    <div className="space-y-4">
      {/* Заголовок */}
      <h2 className="text-xl font-bold mb-4">
        {t.availableRoutes} ({routes.length})
      </h2>

      {/* Список маршрутов */}
      <div className="space-y-4">
        {routes.map((route, index) => (
          <RouteItem 
            key={route.id || index} 
            route={route} 
          />
        ))}
      </div>
    </div>
  );
};

export default RouteList;