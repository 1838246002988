import axios from 'axios';
import qs from 'qs';
import PaymentAPI from './payment-api';

// Базовая конфигурация API
const CONFIG = {
  BASE_URL: '/api',
  DEFAULT_HEADERS: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Application-Origin': 'AFF',
    'X-Lang': 'en'
  },
  AUTH: {
    USERNAME: 'jobsapp_api',
    PASSWORD: 'sh63FHlF'
  },
  SUPPORTED: {
    LANGUAGES: ['cs', 'de', 'en', 'es', 'fr', 'hu', 'ru', 'sk', 'uk', 'zh'],
    CURRENCIES: ['CZK', 'EUR'],
    LOCATION_TYPES: {
      CITY: 'CITY',
      STATION: 'STATION'
    },
    VEHICLE_TYPES: {
      BUS: 'BUS',
      TRAIN: 'TRAIN'
    },
    PAYMENT_METHODS: {
      REGIOJET_PAY: 'REGIOJET_PAY'
    },
    PAYMENT_STATUSES: {
      PENDING: 'PENDING',
      PAID: 'PAID',
      FAILED: 'FAILED',
      CANCELLED: 'CANCELLED',
      EXPIRED: 'EXPIRED'
    }
  },
  DEFAULTS: {
    TARIFF: 'REGULAR',
    LOCATION_TYPE: 'CITY',
    SEAT_CLASS: 'REGULAR',
    CURRENCY: 'EUR',
    LANG: 'en'
  }
};

class RegioJetAPI {
  constructor() {
    this.CONFIG = CONFIG;
    
    // Создаем инстанс axios с базовыми настройками
    this.api = axios.create({
      baseURL: this.CONFIG.BASE_URL, // /api
      headers: this.CONFIG.DEFAULT_HEADERS,
      paramsSerializer: {
        serialize: params => qs.stringify(params, {
          arrayFormat: 'repeat',
          encode: false
        })
      }
    });

    // Создаем отдельный инстанс для authenticate запросов
    this.authApi = axios.create({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Application-Origin': 'AFF',
        'X-Lang': 'en'
      }
    });

    this.setupInterceptors();
    this.payment = new PaymentAPI(this);
  }

  // Отдельный метод для аутентификации
  createAuthRequest(token) {
    const cleanToken = token.trim();
    return {
      method: 'GET',
      url: '/api/users/authenticate',
      headers: {
        'Authorization': `Bearer ${cleanToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Application-Origin': 'AFF',
        'X-Lang': 'en'
      }
    };
  }

  btoa(str) {
    try {
      return window.btoa(str);
    } catch (err) {
      return window.btoa(unescape(encodeURIComponent(str)));
    }
  }

  setupInterceptors() {
    this.api.interceptors.request.use(
      config => {
        console.log('Making request:', {
          url: config.url,
          method: config.method,
          headers: this.sanitizeHeaders(config.headers)
        });
  
        // Для эндпоинта аутентификации не добавляем Basic auth
        if (config.url.includes('/users/authenticate')) {
          return config;
        }
  
        // Для остальных запросов используем Basic auth
        const auth = this.btoa(`${CONFIG.AUTH.USERNAME}:${CONFIG.AUTH.PASSWORD}`);
        config.headers.Authorization = `Basic ${auth}`;
        
        // Добавляем стандартные заголовки
        config.headers = {
          ...config.headers,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Application-Origin': 'AFF',
          'X-Lang': 'en'
        };
  
        return config;
      },
      error => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
      }
    );
  
    this.api.interceptors.response.use(
      response => {
        console.log('Response:', {
          url: response.config.url,
          status: response.status,
          hasData: !!response.data
        });
        return response;
      },
      error => {
        // Подробное логирование ошибок
        const errorDetails = {
          url: error.config?.url,
          method: error.config?.method,
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          headers: this.sanitizeHeaders(error.config?.headers)
        };
  
        // Специальная обработка ошибок аутентификации
        if (error.response?.status === 401) {
          if (error.config?.url.includes('/users/authenticate')) {
            console.error('Authentication failed:', errorDetails);
            return Promise.reject(new Error('Authentication failed: Invalid token'));
          }
          console.error('Authorization error:', errorDetails);
          return Promise.reject(new Error('Authorization failed: Invalid credentials'));
        }
  
        // Общее логирование ошибок
        console.error('API Error:', errorDetails);
  
        // Обработка специфичных ошибок
        if (error.response?.data?.message) {
          return Promise.reject(new Error(error.response.data.message));
        }
  
        // Обработка сетевых ошибок
        if (error.request && !error.response) {
          console.error('Network error:', error);
          return Promise.reject(new Error('Network error: Unable to reach the server'));
        }
  
        return Promise.reject(this.handleError(error));
      }
    );
  
    // Добавляем отдельный обработчик для отмены запросов
    const cancelToken = axios.CancelToken;
    this.requestCancelSource = cancelToken.source();
  }

  async authenticate(token) {
    let cleanToken = '';
    
    try {
      if (!token) {
        throw new Error('Token is required');
      }
      
      cleanToken = token.trim();
      const requestConfig = this.createAuthRequest(cleanToken);
  
      console.log('Authentication request details:', {
        cleanToken,
        url: requestConfig.url,
        headers: requestConfig.headers
      });
  
      const response = await this.authApi(requestConfig);
  
      console.log('Response details:', {
        status: response.status,
        data: response.data
      });
  
      if (!response.data?.accountCode) {
        throw new Error('Invalid authentication response');
      }
  
      return response.data;
  
    } catch (error) {
      console.error('Authentication error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        token: cleanToken
      });
  
      throw error;
    }
  }

  // Location methods
  async getLocations(type = null) {
    try {
      console.log('Fetching locations:', { type });
      const params = type ? { stationType: type } : {};
      const response = await this.api.get('/consts/locations', { params });
      return this.processLocationsResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getStationsByCity(cityId) {
    try {
      const locations = await this.getLocations();
      const city = locations.find(city => city.id === cityId);
      return city?.stations || [];
    } catch (error) {
      console.error('Error fetching stations for city:', cityId, error);
      throw this.handleError(error);
    }
  }

  async getStationById(stationId) {
    try {
      const locations = await this.getLocations();
      for (const city of locations) {
        const station = city.stations.find(station => station.id === stationId);
        if (station) {
          return {
            ...station,
            cityName: city.name,
            countryCode: city.countryCode
          };
        }
      }
      throw new Error(`Station with ID ${stationId} not found`);
    } catch (error) {
      console.error('Error fetching station info:', stationId, error);
      throw this.handleError(error);
    }
  }

 // Response processors
processTariffsResponse(data) {
  if (!Array.isArray(data)) {
    console.warn('Unexpected tariffs data format');
    return [];
  }

  return data.map(tariff => ({
    key: tariff.key || tariff.code,
    value: tariff.value || tariff.name,
    name: tariff.name,
    description: tariff.description,
    priceMultiplier: Number(tariff.priceMultiplier || 1),
    isDefault: Boolean(tariff.isDefault),
    validFrom: tariff.validFrom ? new Date(tariff.validFrom) : null,
    validTo: tariff.validTo ? new Date(tariff.validTo) : null,
    conditions: tariff.conditions || [],
    benefits: tariff.benefits || [],
    eligibilityRules: tariff.eligibilityRules || [],
    requiredDocuments: tariff.requiredDocuments || []
  }));
}

processSeatClassesResponse(data) {
  if (!Array.isArray(data)) {
    console.warn('Unexpected seat classes data format');
    return [];
  }

  return data.map(seatClass => ({
    key: seatClass.key,
    vehicleClass: seatClass.vehicleClass,
    title: seatClass.title,
    description: seatClass.description,
    imageUrl: seatClass.imageUrl,
    galleryUrl: seatClass.galleryUrl,
    priceMultiplier: Number(seatClass.priceMultiplier || 1),
    isDefault: Boolean(seatClass.isDefault),
    features: seatClass.features || [],
    services: seatClass.services || []
  }));
}

processRouteDetailsResponse(data) {
  if (!data) return null;

  return {
    ...data,
    departureTime: data.departureTime ? new Date(data.departureTime) : null,
    arrivalTime: data.arrivalTime ? new Date(data.arrivalTime) : null,
    priceClasses: data.priceClasses?.map(priceClass => ({
      ...priceClass,
      price: Number(priceClass.price),
      creditPrice: Number(priceClass.creditPrice)
    })) || [],
    mainSectionId: data.mainSectionId,
    priceSource: data.priceClasses?.[0]?.priceSource
  };
}

processSeatsResponse(data) {
  if (!Array.isArray(data)) {
    console.warn('Unexpected seats data format');
    return [];
  }

  return data.map(section => ({
    sectionId: section.sectionId,
    fromStationId: section.fromStationId,
    toStationId: section.toStationId,
    fixedSeatReservation: section.fixedSeatReservation,
    vehicles: (section.vehicles || []).map(vehicle => ({
      id: vehicle.id || vehicle.vehicleId,
      number: vehicle.vehicleNumber || vehicle.number,
      standardKey: vehicle.vehicleStandardKey || vehicle.standardKey,
      services: vehicle.services || [],
      seats: (vehicle.freeSeats || []).map(seat => {
        const seatIndex = typeof seat.index === 'number' ? seat.index : parseInt(seat.number, 10);
        return {
          id: `${vehicle.vehicleNumber}-${seatIndex}`,
          index: seatIndex,
          number: seat.number || seatIndex.toString(),
          seatClass: seat.seatClass || CONFIG.DEFAULTS.SEAT_CLASS,
          constraint: seat.seatConstraint,
          notes: seat.seatNotes || [],
          available: true,
          price: seat.price,
          currency: seat.currency || CONFIG.DEFAULTS.CURRENCY,
          vehicleNumber: vehicle.vehicleNumber || vehicle.number
        };
      })
    }))
  }));
}

processTicketResponse(data) {
  if (!data) {
    throw new Error('Empty ticket data received');
  }

  const processedTicket = {
    id: data.id || data.ticketId,
    accountCode: data.accountCode,
    token: data.token,
    controlHash: data.controlHash,
    status: data.status,
    price: Number(data.totalPrice || data.price || 0),
    currency: data.currency || CONFIG.DEFAULTS.CURRENCY,
    sections: data.sections || [],
    passengers: data.passengers || [],
    route: data.route || {}
  };

  return processedTicket;
} 

  // Route methods
  async searchRoutes({
    fromLocationId,
    toLocationId,
    departureDate,
    fromLocationType = CONFIG.DEFAULTS.LOCATION_TYPE,
    toLocationType = CONFIG.DEFAULTS.LOCATION_TYPE,
    tariffs = [CONFIG.DEFAULTS.TARIFF]
  }) {
    try {
      const params = {
        fromLocationId: this.parseId(fromLocationId),
        toLocationId: this.parseId(toLocationId),
        fromLocationType,
        toLocationType,
        departureDate: this.formatDate(departureDate),
        tariffs
      };

      const response = await this.api.get('/routes/search/simple', { params });
      return this.processRoutesResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getRouteDetails({
    routeId,
    fromStationId,
    toStationId,
    departureDate,
    tariffs = [CONFIG.DEFAULTS.TARIFF]
  }) {
    try {
      if (!routeId || !fromStationId || !toStationId || !departureDate) {
        throw new Error('Required parameters missing');
      }

      const params = {
        fromStationId: this.parseId(fromStationId),
        toStationId: this.parseId(toStationId),
        departureDate: this.formatDate(departureDate),
        tariffs: Array.isArray(tariffs) ? tariffs : [tariffs]
      };

      console.log('Getting route details:', { routeId, params });

      const response = await this.api.get(`/routes/${routeId}/simple`, { params });
      return this.processRouteDetailsResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getFreeSeats(routeId, params = {}) {
    try {
      if (!routeId || !params.fromStationId || !params.toStationId) {
        throw new Error('Required parameters missing for seats request');
      }

      const requestData = {
        sections: [{
          sectionId: routeId,
          fromStationId: this.parseId(params.fromStationId),
          toStationId: this.parseId(params.toStationId)
        }],
        tariffs: [params.tariff || CONFIG.DEFAULTS.TARIFF],
        seatClass: params.seatClass || CONFIG.DEFAULTS.SEAT_CLASS
      };

      const response = await this.api.post(`/routes/${routeId}/freeSeats`, requestData);
      return this.processSeatsResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getPassengersData(routeId, data) {
    try {
      const response = await this.api.post(`/routes/${routeId}/passengersData`, data);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getVehicleStandards() {
    try {
      const response = await this.api.get('/consts/vehicleStandards');
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  // Tariff methods
  async getTariffs() {
    try {
      console.log('Fetching tariffs');
      const response = await this.api.get('/consts/tariffs');
      return this.processTariffsResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getSeatClasses() {
    try {
      console.log('Fetching seat classes');
      const response = await this.api.get('/consts/seatClasses');
      return this.processSeatClassesResponse(response.data);
    } catch (error) {
      console.error('Error fetching seat classes:', error);
      throw this.handleError(error);
    }
  }

  async getDefaultTariff() {
    try {
      console.log('Getting default tariff');
      const tariffs = await this.getTariffs();
      const defaultTariff = tariffs.find(tariff => tariff.isDefault) || tariffs[0];
      
      console.log('Default tariff:', defaultTariff);
      return defaultTariff;
    } catch (error) {
      console.error('Error getting default tariff:', error);
      throw this.handleError(error);
    }
  }

  async getTariffByKey(tariffKey) {
    try {
      const tariffs = await this.getTariffs();
      const tariff = tariffs.find(t => t.key === tariffKey);
      if (!tariff) {
        throw new Error(`Tariff with key ${tariffKey} not found`);
      }
      return tariff;
    } catch (error) {
      console.error('Error getting tariff by key:', error);
      throw this.handleError(error);
    }
  }

  async validateTariff(tariffKey, passengerData) {
    try {
      const tariff = await this.getTariffByKey(tariffKey);
      
      console.log('Validating tariff:', {
        tariffKey,
        passengerData: this.sanitizeData(passengerData)
      });

      if (tariff.validFrom && new Date() < tariff.validFrom) {
        throw new Error('Tariff is not yet valid');
      }
      if (tariff.validTo && new Date() > tariff.validTo) {
        throw new Error('Tariff has expired');
      }

      if (tariff.eligibilityRules?.length) {
        const isEligible = tariff.eligibilityRules.every(rule => {
          switch (rule.type) {
            case 'AGE':
              const age = this.calculateAge(new Date(passengerData.dateOfBirth));
              return age >= rule.minAge && age <= rule.maxAge;
            case 'DOCUMENT':
              return rule.requiredDocuments.some(doc => 
                passengerData.documents?.includes(doc)
              );
            default:
              return true;
          }
        });

        if (!isEligible) {
          throw new Error('Passenger is not eligible for this tariff');
        }
      }

      return true;
    } catch (error) {
      console.error('Tariff validation error:', error);
      throw this.handleError(error);
    }
  }

  // Ticket methods
  async createTicket(bookingData) {
    try {
      console.log('Creating ticket with data:', {
        routeId: bookingData.routeId,
        seatClass: bookingData.selectedSeat?.seatClass,
        passengers: bookingData.passengers?.length
      });

      if (!this.validateBookingData(bookingData)) {
        throw new Error('Invalid booking data');
      }

      const ticketRequest = {
        agreeWithTerms: true,
        ticketRequests: [{
          route: {
            routeId: bookingData.routeId,
            seatClass: bookingData.selectedSeat.seatClass || CONFIG.DEFAULTS.SEAT_CLASS,
            priceSource: bookingData.selectedRoute.priceSource,
            sections: [{
              section: {
                sectionId: bookingData.selectedRoute.mainSectionId || bookingData.routeId,
                fromStationId: bookingData.selectedRoute.departureStationId,
                toStationId: bookingData.selectedRoute.arrivalStationId
              },
              selectedSeats: [{
                sectionId: bookingData.selectedRoute.mainSectionId || bookingData.routeId,
                vehicleNumber: String(bookingData.selectedSeat.vehicleNumber),
                seatIndex: String(bookingData.selectedSeat.number || bookingData.selectedSeat.index)
              }]
            }]
          },
          passengers: bookingData.passengers.map(passenger => ({
            firstName: passenger.firstName.trim(),
            surname: passenger.lastName.trim(),
            email: passenger.email.trim(),
            phone: passenger.phone.trim(),
            dateOfBirth: passenger.dateOfBirth || '1990-01-01',
            tariff: passenger.tariff || CONFIG.DEFAULTS.TARIFF
          }))
        }]
      };

      console.log('Sending ticket request:', ticketRequest);

      const response = await this.api.post('/tickets/create', ticketRequest);

      console.log('Ticket creation response:', {
        success: true,
        hasData: !!response.data,
        hasToken: !!response.data?.token,
        tokenPreview: response.data?.token ? `${response.data.token.substring(0, 10)}...` : null,
        hasTickets: !!response.data?.tickets,
        ticketsCount: response.data?.tickets?.length
      });

      if (!response?.data?.tickets?.length || !response?.data?.token) {
        throw new Error('Invalid ticket creation response: missing tickets or token');
      }

      return {
        ticket: this.processTicketResponse(response.data.tickets[0]),
        token: response.data.token
      };

    } catch (error) {
      console.error('Ticket creation failed:', error);
      throw error;
    }
  }

  // Additional Ticket Methods
  async cancelTicket(accountCode, ticketId, controlHash) {
    try {
      console.log('Cancelling ticket:', { accountCode, ticketId });

      const response = await this.api.put(
        `/tickets/${accountCode}/${ticketId}/cancel`,
        { controlHash },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );

      return response.data;
    } catch (error) {
      console.error('Cancel ticket error:', error);
      throw this.handleError(error);
    }
  }

  async getTickets(accountCode) {
    try {
      const response = await this.api.get(`/tickets/${accountCode}`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getTicketById(accountCode, ticketId) {
    try {
      const response = await this.api.get(`/tickets/${accountCode}/${ticketId}`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async printTicket(accountCode, ticketId, token) {
    try {
      const cleanToken = token.replace(/^Bearer\s+/i, '').trim();
      const authToken = `Bearer ${cleanToken}`;

      return await this.api.get(
        `/tickets/${accountCode}/${ticketId}/print`,
        {
          responseType: 'blob',
          headers: {
            'Authorization': authToken,
            'Accept': 'text/html'
          }
        }
      );
    } catch (error) {
      console.error('Print ticket error:', error);
      throw this.handleError(error);
    }
  }

  async getTicketQR(accountCode, ticketId, token) {
    try {
      const cleanToken = token.replace(/^Bearer\s+/i, '').trim();
      const authToken = `Bearer ${cleanToken}`;

      return await this.api.get(
        `/tickets/${accountCode}/${ticketId}/qrcode/png`,
        {
          responseType: 'blob',
          headers: {
            'Authorization': authToken,
            'Accept': 'image/png'
          }
        }
      );
    } catch (error) {
      console.error('Get ticket QR error:', error);
      throw this.handleError(error);
    }
  }

  async evaluateTicket(accountCode, ticketId, rating) {
    try {
      const response = await this.api.post(
        `/tickets/${accountCode}/${ticketId}/evaluate`,
        rating
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async sendTicketByEmail(accountCode, ticketId, email) {
    try {
      const response = await this.api.post(
        `/tickets/${accountCode}/${ticketId}/send`,
        { email }
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async printInvoice(accountCode, ticketId) {
    try {
      const response = await this.api.get(
        `/tickets/${accountCode}/${ticketId}/invoice`,
        { responseType: 'blob' }
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  // Response processors
  processLocationsResponse(data) {
    if (!Array.isArray(data)) {
      console.warn('Unexpected locations data format');
      return [];
    }

    return data.flatMap(country => 
      (country.cities || []).map(city => ({
        id: city.id,
        name: city.name,
        countryCode: country.code,
        type: CONFIG.SUPPORTED.LOCATION_TYPES.CITY,
        aliases: city.aliases || [],
        stations: (city.stations || []).map(station => ({
          id: station.id,
          name: station.fullname || station.name,
          type: station.stationsTypes?.[0] || CONFIG.SUPPORTED.LOCATION_TYPES.STATION,
          address: station.address,
          latitude: station.latitude,
          longitude: station.longitude,
          significance: station.significance || 0
        }))
      }))
    );
  }

  processRoutesResponse(data) {
    if (!data?.routes) {
      return { routes: [], message: data?.routesMessage };
    }

    return {
      routes: data.routes.map(route => ({
        id: route.id,
        departureTime: new Date(route.departureTime),
        arrivalTime: new Date(route.arrivalTime),
        departureStationId: route.departureStationId,
        arrivalStationId: route.arrivalStationId,
        price: route.priceFrom,
        currency: route.currency || CONFIG.DEFAULTS.CURRENCY,
        vehicleTypes: route.vehicleTypes || [],
        freeSeatsCount: route.freeSeatsCount,
        transfersCount: route.transfersCount || 0,
        bookable: route.bookable,
        mainSectionId: route.mainSectionId,
        priceSource: route.priceSource,
        travelTime: route.travelTime,
        delay: route.delay
      })),
      message: data.routesMessage
    };
  }

  // Error handlers
  handleError(error) {
    if (error.response) {
      const { data, status } = error.response;
      let message = data?.message || 'An unexpected error occurred';

      if (data?.errorFields?.length) {
        message = data.errorFields
          .map(field => `${field.field}: ${field.message}`)
          .join('; ');
      }

      const apiError = new Error(message);
      apiError.status = status;
      apiError.details = data;
      return apiError;
    }

    if (error.request) {
      return new Error('No response received from server');
    }

    return error;
  }

  handlePaymentError(error) {
    if (error.response) {
      const { status, data } = error.response;
      const message = data?.message || 'Payment operation failed';

      switch (status) {
        case 400: return new Error(`Invalid payment request: ${message}`);
        case 401: return new Error('Payment authorization failed');
        case 403: return new Error('Payment operation forbidden');
        case 404: return new Error('Payment not found');
        case 409: return new Error('Payment already processed');
        case 500: return new Error('Payment service temporarily unavailable');
        default: return new Error(`Payment error: ${message}`);
      }
    }

    if (error.request) {
      return new Error('Payment service is not responding');
    }

    return new Error(error.message || 'Unexpected payment error');
  }

  // Utility methods
  validateBookingData(bookingData) {
    const validationErrors = [];

    if (!bookingData.routeId) validationErrors.push('Route ID is missing');
    if (!bookingData.selectedRoute) validationErrors.push('Selected route information is missing');
    if (!bookingData.selectedSeat) validationErrors.push('Selected seat information is missing');

    if (!bookingData.passengers?.length) {
      validationErrors.push('Passenger information is missing');
    } else {
      bookingData.passengers.forEach((passenger, index) => {
        if (!passenger.firstName) validationErrors.push(`Passenger ${index + 1} first name is missing`);
        if (!passenger.lastName) validationErrors.push(`Passenger ${index + 1} last name is missing`);
        if (!passenger.email) validationErrors.push(`Passenger ${index + 1} email is missing`);
        if (!passenger.phone) validationErrors.push(`Passenger ${index + 1} phone is missing`);
      });
    }

    if (validationErrors.length > 0) {
      throw new Error(validationErrors.join('; '));
    }

    return true;
  }

  parseId(id) {
    if (typeof id === 'number') return id;
    if (typeof id === 'string') return parseInt(id, 10);
    if (typeof id === 'object' && id !== null) {
      if ('id' in id) return this.parseId(id.id);
      if ('stationId' in id) return this.parseId(id.stationId);
    }
    throw new Error(`Invalid ID format: ${JSON.stringify(id)}`);
  }

  formatDate(date) {
    try {
      if (date instanceof Date) {
        return date.toISOString().split('T')[0];
      }
      if (typeof date === 'string') {
        const dateObj = new Date(date);
        if (isNaN(dateObj.getTime())) {
          throw new Error('Invalid date string');
        }
        return dateObj.toISOString().split('T')[0];
      }
      throw new Error('Invalid date format');
    } catch (error) {
      throw new Error(`Date formatting error: ${error.message}`);
    }
  }

  calculateAge(birthDate) {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    return age;
  }

  sanitizeData(data) {
    if (!data) return data;
    const sensitiveFields = ['password', 'token', 'auth', 'creditCard'];
    return JSON.parse(JSON.stringify(data), (key, value) => 
      sensitiveFields.includes(key) ? '[REDACTED]' : value
    );
  }

  sanitizeHeaders(headers) {
    if (!headers) return headers;
    const sanitized = { ...headers };
    if (sanitized.Authorization) {
      sanitized.Authorization = sanitized.Authorization.substring(0, 20) + '...';
    }
    return sanitized;
  }

  getStatusMessage(status) {
    const messages = {
      PENDING: 'Payment is being processed',
      PAID: 'Payment completed successfully',
      FAILED: 'Payment failed',
      CANCELLED: 'Payment was cancelled',
      EXPIRED: 'Payment has expired'
    };
    return messages[status] || `Unknown payment status: ${status}`;
  }
}

export default new RegioJetAPI();