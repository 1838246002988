export const LANGUAGES = {
  EN: 'en',
  RU: 'ru'
};

export const TRANSLATIONS = {
  [LANGUAGES.EN]: {
    title: "Travel that moves you",
    subtitle: "Book train, bus, flight and ferry tickets",
    from: "From",
    to: "To",
    date: "Date",
    passengers: "passenger",
    passengers_plural: "passengers",
    search: "Search",
    popular: "Popular destinations",
    availableRoutes: "Available Routes",
    routeDetails: "Route Details",
    departure: "Departure",
    arrival: "Arrival",
    availableSeats: "Available Seats",
    bookingForm: "Booking Form",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    selectSeat: "Select a Seat",
    book: "Book",
    bookingConfirmed: "Booking Confirmed!",
    ticketId: "Ticket ID",
    cancelBooking: "Cancel Booking",
    bookingCancelled: "Booking Cancelled",
    cancelBookingError: "Error cancelling booking",
    bookingHistory: "Booking History",
    departureTime: "Departure Time",
    arrivalTime: "Arrival Time",
    price: "Price",
    errorFetchingHistory: "Error fetching booking history",
    noBookingsFound: "No bookings found",
    routeDetailsNotAvailable: "Route details not available",
    filterAndSort: "Filter and Sort",
    priceRange: "Price Range",
    min: "Min",
    max: "Max",
    sortBy: "Sort By",
    sortOrder: "Sort Order",
    ascending: "Ascending",
    descending: "Descending",
    apply: "Apply",
    retry: "Retry",
    adult: "adult",
    adults: "adults",
    searchRoutes: "Search routes",
    addReturn: "Add return",
    removeReturn: "Remove return",
    popularDestinations: "Popular destinations right now",
    chooseDestination: "Choose between trains, buses, flights and ferries and book your perfect ticket today.",
    backToSearch: "Back to search",
    backToResults: "Back to results",
    loading: "Loading...",
    pleaseWait: "Please wait while we prepare your data",
    errorFetchingSeats: "Error fetching available seats",
    selectedRoute: "Selected Route",
    selectedSeat: "Selected Seat",
    seatNumber: "Seat Number",
    confirmBooking: "Confirm Booking",
    passengerDetails: "Passenger Details",
    bookingStep: "Booking Step",
    step1: "Select Route",
    step2: "Choose Seat",
    step3: "Enter Details",
    noSeatsAvailable: "No seats available for this route",
    selectDifferentRoute: "Please select a different route",
    bookingSuccessful: "Your booking was successful!",
    bookingFailed: "Booking failed. Please try again.",
    returnToHome: "Return to Home",
    totalPrice: "Total Price",
    currency: "Currency",
    transportType: "Transport Type",
    train: "Train",
    bus: "Bus",
    plane: "Plane",
    closeModal: "Close",
    ferry: "Ferry",
    title: "Travel that moves you",
    subtitle: "Book train, bus, flight and ferry tickets",
    addReturn: "Add return",
    removeReturn: "Remove return",
    findAccommodation: "Find my accommodation",
    seatsAvailable: 'seats available',
    bus: 'Bus',
    train: 'Train',
    duration: 'Duration',
    noRoutesFound: 'No routes found',
    availableRoutes: 'Available Routes',
    selectedSeat: 'Selected Seat',
  price: 'Price',
  currency: 'CZK',
  noSeatsAvailable: 'No seats available',
  errorFetchingSeats: 'Error loading seats',
  routeDetails: 'Route Details',
  departure: 'Departure',
  arrival: 'Arrival',
  from: 'From',
  to: 'To',
  availableSeats: 'Available Seats',
  duration: 'Duration',
  transfers: 'Transfers',
  delay: 'Delay',
  loadingSeats: 'Loading available seats...',
  errorFetchingSeats: 'Error loading seats',
  retry: 'Retry',
  noSeatsAvailable: 'No seats available',
  selectDifferentRoute: 'Please select a different route',
  selectSeat: 'Select your seat',
  vehicle: 'Vehicle',
  selectedSeat: 'Selected Seat',
  seatNumber: 'Seat Number',
  class: 'Class',
  price: 'Price',
  loadingSeats: 'Loading seats...',
  retry: 'Retry',
  noSeatsAvailable: 'No seats available',
  selectDifferentRoute: 'Please select a different route',
  vehicle: 'Vehicle',
  availableServices: 'Available Services',
  selectedSeat: 'Selected Seat',
  confirm: 'Confirm Selection',
  passengerDetails: 'Passenger Details',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  dateOfBirth: 'Date of Birth',
  bookingSummary: 'Booking Summary',
  from: 'From',
  to: 'To',
  departure: 'Departure',
  seat: 'Seat',
  vehicle: 'Vehicle',
  price: 'Price',
  confirmBooking: 'Confirm Booking',
  passengerDetails: 'Passenger Details',
  bookingSummary: 'Booking Summary',
  route: 'Route',
  seat: 'Seat',
  vehicle: 'Vehicle',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  dateOfBirth: 'Date of Birth',
  processing: 'Processing...',
  confirmBooking: 'Confirm Booking',
  bookingError: 'Error while creating booking',
  from: 'From',
  to: 'To',
  departure: 'Departure',
  bookingConfirmed: 'Booking Confirmed',
  ticketId: 'Ticket ID',
  departure: 'Departure',
  route: 'Route',
  passenger: 'Passenger',
  price: 'Price',
  printTicket: 'Print Ticket',
  cancelBooking: 'Cancel Booking',
  close: 'Close',
    payment: 'Payment',
    paymentMethod: 'Payment Method',
    instantPayment: 'Instant payment via RegioJet Pay',
    totalAmount: 'Total Amount',
    processing: 'Processing...',
    pay: 'Pay Now',
    cancel: 'Cancel',
    paymentSuccess: 'Payment Successful',
    ticketReady: 'Your ticket is ready',
    viewTicket: 'View Ticket',
    paymentError: 'Payment failed. Please try again.',
    phoneFormat: "Format: +420XXXXXXXXX",
  invalidPhone: "Please enter a valid phone number in format +420XXXXXXXXX",
  firstNameRequired: "First name is required",
  lastNameRequired: "Last name is required",
  invalidEmail: "Please enter a valid email address",
  invalidDateOfBirth: "Please enter a valid date of birth",
  requiredFields: "Required fields",
  invalidBooking: 'Invalid booking data',
  invalidPaymentResponse: 'Invalid payment response',
  popupBlocked: 'Please enable popups for payment processing',
  paymentError: 'Payment error occurred',
  paymentConfirmationError: 'Payment confirmation error',
  paymentCancelled: 'Payment was cancelled',
  paymentCancellationError: 'Error cancelling payment',
  processingPayment: 'Processing payment...',
  paymentWindowOpen: 'Payment window is open. Please complete the payment there.',
  securePayment: 'Secure payment processing',
  payment_pending: 'Payment is being processed',
  payment_paid: 'Payment successful',
  payment_failed: 'Payment failed',
  payment_cancelled: 'Payment cancelled',
  payment_expired: 'Payment expired'
  },


  [LANGUAGES.RU]: {
    title: "Путешествие, которое вас вдохновляет",
    subtitle: "Бронирование билетов на поезд, автобус, самолет или паром",
    from: "Откуда",
    to: "Куда",
    date: "Дата",
    passengers: "пассажир",
    passengers_plural: "пассажиров",
    search: "Поиск",
    popular: "Популярные направления",
    availableRoutes: "Доступные маршруты",
    routeDetails: "Детали маршрута",
    departure: "Отправление",
    arrival: "Прибытие",
    availableSeats: "Свободные места",
    bookingForm: "Форма бронирования",
    firstName: "Имя",
    lastName: "Фамилия",
    email: "Эл. почта",
    phone: "Телефон",
    selectSeat: "Выберите место",
    book: "Забронировать",
    bookingConfirmed: "Бронирование подтверждено!",
    ticketId: "Номер билета",
    cancelBooking: "Отменить бронирование",
    bookingCancelled: "Бронирование отменено",
    cancelBookingError: "Ошибка при отмене бронирования",
    bookingHistory: "История бронирований",
    departureTime: "Время отправления",
    arrivalTime: "Время прибытия",
    price: "Цена",
    errorFetchingHistory: "Ошибка при загрузке истории бронирований",
    noBookingsFound: "Бронирования не найдены",
    routeDetailsNotAvailable: "Детали маршрута недоступны",
    filterAndSort: "Фильтр и сортировка",
    priceRange: "Диапазон цен",
    min: "Мин",
    max: "Макс",
    sortBy: "Сортировать по",
    sortOrder: "Порядок сортировки",
    ascending: "По возрастанию",
    descending: "По убыванию",
    apply: "Применить",
    retry: "Повторить",
    adult: "взрослый",
    adults: "взрослых",
    searchRoutes: "Искать маршруты",
    addReturn: "Добавить обратный",
    removeReturn: "Убрать обратный",
    popularDestinations: "Популярные направления прямо сейчас",
    chooseDestination: "Выберите между поездами, автобусами, рейсами и паромами и забронируйте идеальный билет сегодня.",
    backToSearch: "Назад к поиску",
    backToResults: "Назад к результатам",
    loading: "Загрузка...",
    pleaseWait: "Пожалуйста, подождите, пока мы подготовим ваши данные",
    errorFetchingSeats: "Ошибка при загрузке доступных мест",
    selectedRoute: "Выбранный маршрут",
    selectedSeat: "Выбранное место",
    seatNumber: "Номер места",
    confirmBooking: "Подтвердить бронирование",
    passengerDetails: "Данные пассажира",
    bookingStep: "Шаг бронирования",
    step1: "Выбор маршрута",
    step2: "Выбор места",
    step3: "Ввод данных",
    noSeatsAvailable: "Нет доступных мест на этом маршруте",
    selectDifferentRoute: "Пожалуйста, выберите другой маршрут",
    bookingSuccessful: "Ваше бронирование успешно выполнено!",
    bookingFailed: "Не удалось выполнить бронирование. Пожалуйста, попробуйте еще раз.",
    returnToHome: "Вернуться на главную",
    totalPrice: "Общая стоимость",
    currency: "Валюта",
    transportType: "Тип транспорта",
    train: "Поезд",
    bus: "Автобус",
    plane: "Самолет",
    closeModal: "Закрыть",
    ferry: "Паром",
    title: "Путешествие, которое вдохновляет вас",
    subtitle: "Бронирование билетов на поезд, автобус, самолет и паром",
    addReturn: "Добавить обратный",
    removeReturn: "Убрать обратный",
    findAccommodation: "Найти жилье",
    seatsAvailable: 'seats available',
    bus: 'Bus',
    train: 'Train',
    duration: 'Duration',
    noRoutesFound: 'No routes found',
    availableRoutes: 'Available Routes',
    vehicle: 'Vehicle',
  selectedSeat: 'Selected Seat',
  price: 'Price',
  currency: 'CZK',
  noSeatsAvailable: 'No seats available',
  errorFetchingSeats: 'Error loading seats',
  routeDetails: 'Route Details',
  departure: 'Departure',
  arrival: 'Arrival',
  from: 'From',
  to: 'To',
  availableSeats: 'Available Seats',
  duration: 'Duration',
  transfers: 'Transfers',
  delay: 'Delay',
  loadingSeats: 'Loading available seats...',
  errorFetchingSeats: 'Error loading seats',
  retry: 'Retry',
  noSeatsAvailable: 'No seats available',
  selectDifferentRoute: 'Please select a different route',
  selectSeat: 'Select your seat',
  vehicle: 'Vehicle',
  selectedSeat: 'Selected Seat',
  seatNumber: 'Seat Number',
  class: 'Class',
  price: 'Price',
  loadingSeats: 'Loading seats...',
  retry: 'Retry',
  noSeatsAvailable: 'No seats available',
  selectDifferentRoute: 'Please select a different route',
  vehicle: 'Vehicle',
  availableServices: 'Available Services',
  selectedSeat: 'Selected Seat',
  confirm: 'Confirm Selection',
  passengerDetails: 'Passenger Details',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  dateOfBirth: 'Date of Birth',
  bookingSummary: 'Booking Summary',
  from: 'From',
  to: 'To',
  departure: 'Departure',
  seat: 'Seat',
  vehicle: 'Vehicle',
  price: 'Price',
  confirmBooking: 'Confirm Booking',
  passengerDetails: 'Passenger Details',
  bookingSummary: 'Booking Summary',
  route: 'Route',
  seat: 'Seat',
  vehicle: 'Vehicle',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  dateOfBirth: 'Date of Birth',
  processing: 'Processing...',
  confirmBooking: 'Confirm Booking',
  bookingError: 'Error while creating booking',
  from: 'From',
  to: 'To',
  departure: 'Departure',
  bookingConfirmed: 'Booking Confirmed',
  ticketId: 'Ticket ID',
  departure: 'Departure',
  route: 'Route',
  passenger: 'Passenger',
  price: 'Price',
  printTicket: 'Print Ticket',
  cancelBooking: 'Cancel Booking',
  close: 'Close',
  payment: 'Payment',
    paymentMethod: 'Payment Method',
    instantPayment: 'Instant payment via RegioJet Pay',
    totalAmount: 'Total Amount',
    processing: 'Processing...',
    pay: 'Pay Now',
    cancel: 'Cancel',
    paymentSuccess: 'Payment Successful',
    ticketReady: 'Your ticket is ready',
    viewTicket: 'View Ticket',
    paymentError: 'Payment failed. Please try again.',
    phoneFormat: "Формат: +420XXXXXXXXX",
  invalidPhone: "Пожалуйста, введите корректный номер телефона в формате +420XXXXXXXXX",
  firstNameRequired: "Необходимо указать имя",
  lastNameRequired: "Необходимо указать фамилию",
  invalidEmail: "Пожалуйста, введите корректный email адрес",
  invalidDateOfBirth: "Пожалуйста, введите корректную дату рождения",
  requiredFields: "Обязательные поля",
  invalidBooking: 'Invalid booking data',
  invalidPaymentResponse: 'Invalid payment response',
  popupBlocked: 'Please enable popups for payment processing',
  paymentError: 'Payment error occurred',
  paymentConfirmationError: 'Payment confirmation error',
  paymentCancelled: 'Payment was cancelled',
  paymentCancellationError: 'Error cancelling payment',
  processingPayment: 'Processing payment...',
  paymentWindowOpen: 'Payment window is open. Please complete the payment there.',
  securePayment: 'Secure payment processing',
  payment_pending: 'Payment is being processed',
  payment_paid: 'Payment successful',
  payment_failed: 'Payment failed',
  payment_cancelled: 'Payment cancelled',
  payment_expired: 'Payment expired'
  }
};

